<template>
  <div class="ok-content">
    <img class="ok-logo" :src="logo" alt="Koleroviy logo">
    <div style="text-align: center">
      <ok-lang />
      <section class="ok-actions">
        <a v-for="s in socials" :key="s.text" target="_blank" :href="s.link" :title="s.text">
          {{ s.text }}
        </a>
        <a v-if="info.lp_site" target="_blank" :href="info.lp_site.link" :title="info.lp_site.text[$i18n.locale]">
          {{ info.lp_site.text[$i18n.locale] }}
        </a>
        <button v-if="info.lp_form" type="button" @click="openFormDialog" v-text="info.lp_form.title[$i18n.locale]" />
      </section>
    </div>
    <div v-if="isDialog" ref="formDialog" class="dialog">
      <h2 v-if="info.lp_form" class="title">{{ info.lp_form.title[$i18n.locale] }}</h2>
      <form
        v-if="info.lp_form"
        :action="`https://koleroviy.com/wp-json/contact-form-7/v1/contact-forms/${info.lp_form.ids[$i18n.locale]}/feedback`"
        method="post"
        ref="callbackForm"
        @submit.prevent="sendForm"
        @reset="closeFormDialog"
      >
        <div class="form-control">
          <input type="text" :placeholder="info.lp_form.name[$i18n.locale]" name="your-name" />
        </div>
        <div class="form-control">
          <input type="tel" :placeholder="info.lp_form.phone[$i18n.locale]" name="your-phone" />
        </div>
        <menu v-if="!loading">
          <button type="reset" v-text="info.lp_form.cancel_button[$i18n.locale]" />
          <button type="submit" v-text="info.lp_form.submit_button[$i18n.locale]" />
        </menu>
        <div v-else class="loader"></div>
      </form>
      <div :class="{ 'form-errors': isErrorMessage }">
        <h4 v-show="formMessage">{{ formMessage }}</h4>
      </div>
      <button type="button" class="dialog-close" @click="closeFormDialog">
        &times;
      </button>
    </div>
  </div>
</template>

<script>
export default {
  /* eslint-disable camelcase */
  name: 'ok-content',
  components: {
    'ok-lang': () => import('@/components/Lang')
  },
  props: {
    loading: Boolean
  },
  data: () => ({
    logo: 'https://ok-lead.skushnir.com.ua/lead-logo.png',
    info: {},
    isDialog: false,
    formMessage: '',
    isErrorMessage: false
  }),
  computed: {
    socials () {
      return this.info.lp_socials ? Object.values(this.info.lp_socials).filter(item => item.link) : []
    }
  },
  methods: {
    openFormDialog () {
      this.isDialog = true
    },
    closeFormDialog () {
      this.isDialog = false
      this.$refs.callbackForm.reset()
      this.formMessage = ''
    },
    async sendForm () {
      this.loading = true
      const formElement = this.$refs.callbackForm
      const { action, method } = formElement
      const body = new FormData(formElement)
      const response = await fetch(action, { method, body })
        .then(res => res.json())
        .catch(err => {
          this.loading = false
          this.isErrorMessage = true
          this.formMessage = err
        })
      if (response.status === 'mail_sent') {
        this.isErrorMessage = false
        this.formMessage = response.message
        setTimeout(this.closeFormDialog, 4000)
      } else {
        this.isErrorMessage = true
        this.formMessage = response.message
      }
      this.loading = false
    }
  },
  async mounted () {
    this.$emit('update:loading', true)
    await fetch('https://koleroviy.com/wp-json/wp/v2/pages/1616')
      .then(async res => {
        const { acf } = await res.json()
        this.info = acf
      })
      .catch(err => alert(err.message))
    this.$emit('update:loading', false)
  }
}

</script>

<style lang="scss">
  .ok {
    &-content {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin: 60px auto;
      flex: 1;
    }

    &-logo {
      display: block;
      background-color: rgba(#000, 0.75);
      margin: 0 auto;
      padding: 1.5rem 2rem;
      width: 90%;
      max-width: 640px;
      border-radius: 16px;
    }

    &-actions {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      z-index: 1;

      a, button {
        position: relative;
        background-color: rgba(#000, 0.75);
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 4px;
        margin-bottom: 4px;
        padding: 0.5rem 1rem;
        width: 240px;
        height: 48px;
        font-size: 24px;
        color: #fff;
        border: 0;
        border-radius: 8px;
        text-align: center;
        text-decoration: none;
        transition: all 0.3s;
        cursor: pointer;
        &:hover {
          background-color: #000;
          // &::after {
          //   width: 100%;
          // }
        }
        // &::after {
        //   position: absolute;
        //   background-color: #fff;
        //   left: 0;
        //   bottom: 0;
        //   height: 2px;
        //   width: 0;
        //   content: '';
        //   transition: width 0.2s ease;
        // }

        img {
          width: 100%;
        }
      }
    }
  }
</style>
